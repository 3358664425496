<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1>관리자 - 케이스 관리</h1>
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <v-btn color="#FF4D00" dark block @click="$router.push('/admin')">
          BACK
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="pa-3"> <h4>검색필터</h4> </v-card-title>
          <v-container fluid>
            <v-row>
              <v-col>
                <v-text-field
                  dense
                  outlined
                  label="CASE ID 검색"
                  hide-details="auto"
                  v-model="filter.case_id"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  dense
                  outlined
                  label="Writer 검색"
                  hide-details="auto"
                  v-model="filter.writer"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  dense
                  outlined
                  label="Customer 검색"
                  hide-details="auto"
                  v-model="filter.customer"
                ></v-text-field>
              </v-col>

              <v-col>
                <v-select
                  dense
                  outlined
                  label="Status"
                  hide-details="auto"
                  :items="statusList"
                  v-model="filter.status"
                >
                </v-select>
              </v-col>
              <v-col>
                <v-select
                  dense
                  outlined
                  label="Escalation"
                  hide-details="auto"
                  :items="escalationList"
                  v-model="filter.escalation"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  dense
                  outlined
                  label="Product 검색"
                  hide-details="auto"
                  v-model="filter.product"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  dense
                  outlined
                  label="Subject 검색"
                  hide-details="auto"
                  v-model="filter.subject"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  dense
                  outlined
                  label="Description 검색"
                  rows="1"
                  hide-details="auto"
                  v-model="filter.description"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <DataGrid
          :data="getFilteredData(allOfSearchFilter)"
          :filterable="false"
          :pageSize="20"
          :pagination="true"
          :virtualScroll="true"
          :loading="loading"
          :clickToEdit="true"
          editMode="cell"
          :columnResizing="true"
          style="width: 100%"
        >
          <GridColumnGroup :frozen="true" width="800" align="left">
            <GridHeaderRow>
              <GridColumn
                field="case_id"
                title="Case ID"
                align="center"
                width="100"
                :sortable="true"
                :rowspan="2"
              ></GridColumn>
              <GridColumn
                field="case_type"
                title="Type"
                align="center"
                width="100"
                :sortable="true"
                :rowspan="2"
              ></GridColumn>
              <GridColumn
                field="case_priority"
                title="Priority"
                align="center"
                width="60"
                :sortable="true"
                :rowspan="2"
              ></GridColumn>
              <GridColumn
                field="case_status"
                title="Status"
                align="center"
                width="100"
                :sortable="true"
                :rowspan="2"
              >
                <template slot="body" slot-scope="scope">
                  {{ getCaseStatus(scope.row.case_status) }}
                </template>
              </GridColumn>
              <GridColumn
                title="L2 Escalation"
                align="center"
                :colspan="2"
              ></GridColumn>
              <GridColumn
                field="case_writer_decode"
                title="Writer"
                align="center"
                width="150"
                :sortable="true"
                :rowspan="2"
              >
                <template slot="body" slot-scope="scope">
                  <div style="word-break: keep-all; width: 100%">
                    {{ scope.row.case_writer_decode }}
                  </div>
                </template>
              </GridColumn>
            </GridHeaderRow>
            <GridHeaderRow>
              <GridColumn
                field="case_is_escalation"
                title="is Escalation"
                align="center"
                width="100"
              >
                <template slot="body" slot-scope="scope">
                  {{ getCaseEscalation(scope.row.case_is_escalation) }}
                </template>
                <template slot="edit" slot-scope="scope">
                  <ComboBox
                    style="width: 100%"
                    v-model="scope.row.case_is_escalation"
                    :editable="false"
                    :data="[
                      { value: 1, text: 'Y' },
                      { value: 0, text: 'N' },
                    ]"
                    class="ml-auto mr-auto mt-auto mb-auto text-center"
                  ></ComboBox>
                </template>
              </GridColumn>
              <GridColumn
                field="case_escalation_id"
                title="Escalation ID"
                align="center"
                :sortable="true"
                width="100"
              ></GridColumn>
            </GridHeaderRow>
          </GridColumnGroup>
          <GridColumnGroup>
            <GridHeaderRow>
              <GridColumn
                field="contract_name"
                title="Project"
                width="400"
              ></GridColumn>
              <GridColumn
                field="company_name"
                title="Customer"
                width="400"
              ></GridColumn>
              <GridColumn
                field="product_name"
                title="Product"
                width="400"
              ></GridColumn>
              <GridColumn
                field="case_product_version"
                title="Product Version"
                width="100"
              ></GridColumn>
              <GridColumn
                field="case_subject"
                title="Subject"
                width="400"
              ></GridColumn>
              <GridColumn
                field="case_description"
                title="Description"
                width="400"
              >
              </GridColumn>
              <GridColumn field="case_open_dttm" title="OpenDttm" width="400">
              </GridColumn>
              <GridColumn field="case_close_dttm" title="CloseDttm" width="400">
              </GridColumn>
            </GridHeaderRow>
          </GridColumnGroup>
          <GridColumnGroup :frozen="true" width="200" align="right">
            <GridHeaderRow>
              <GridColumn title="Option" align="center">
                <template slot="body" slot-scope="scope">
                  <LinkButton
                    iconCls="icon-search"
                    class="mx-1"
                    @click="goToCase(scope)"
                  >
                    Detail
                  </LinkButton>
                  <LinkButton
                    iconCls="icon-remove"
                    class="mx-1"
                    @click="removeCase(scope)"
                  >
                    Remove
                  </LinkButton>
                </template>
              </GridColumn>
            </GridHeaderRow>
          </GridColumnGroup>
        </DataGrid>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getCaseLists, deleteCase } from "@/api/Admin/CaseGrid";
import moment from "moment";
export default {
  mounted() {
    this.loadCaseGrid();
  },
  computed: {
    allOfSearchFilter() {
      const filtersKey = Object.keys(this.filter);
      return filtersKey.map((e) => this.filter[e]).join(",");
    },
  },
  methods: {
    getFilteredData() {
      console.log(this.allOfSearchFilter);
      let filteredData = this.caseGridData || [];
      filteredData = filteredData.filter((e) => {
        if (e.case_id == null) return false;
        return e.case_id.includes(this.filter.case_id);
      });
      filteredData = filteredData.filter((e) => {
        if (e.case_writer_decode == null) return false;
        return e.case_writer_decode.includes(this.filter.writer);
      });
      filteredData = filteredData.filter((e) => {
        if (e.company_name == null) return false;
        return e.company_name.includes(this.filter.customer);
      });
      filteredData = filteredData.filter((e) => {
        if (e.product_name == null) return false;
        return e.product_name.includes(this.filter.product);
      });
      filteredData = filteredData.filter((e) => {
        if (e.case_subject == null) return false;
        return e.case_subject.includes(this.filter.subject);
      });
      filteredData = filteredData.filter((e) => {
        if (this.filter.status == null) return true;
        if (e.case_status == null) return false;
        return e.case_status == this.filter.status;
      });
      filteredData = filteredData.filter((e) => {
        if (this.filter.escalation == null) return true;
        if (e.case_is_escalation == null) return false;
        return e.case_is_escalation == this.filter.escalation;
      });
      filteredData = filteredData.filter((e) => {
        if (e.case_description == null) return false;
        return e.case_description.includes(this.filter.description);
      });

      console.log(filteredData);
      return filteredData;
    },
    goToCase(scope) {
      this.$router.push(`/case/${scope.row.case_uuid}`);
    },
    async removeCase(scope) {
      const flag = confirm("제거하시겠습니까?");
      if (flag) {
        const rsp = await deleteCase(scope.row.case_uuid);
        if (rsp.success) {
          scope.row.case_status = 99;
        }
      }
    },
    getCaseStatus(statusID) {
      if (statusID == null) return "ERROR";
      if (statusID == 1) return "NEW";
      if (statusID == 2) return "ASSESS";
      if (statusID == 3) return "RESEARCH";
      if (statusID == 4) return "SOLUTION";
      if (statusID == 5) return "CLOSED";
      if (statusID == 99) return "DELETED";
    },
    getCaseEscalation(is_escalation) {
      if (is_escalation == null) return "ERROR";
      if (is_escalation == 0) return "N";
      if (is_escalation == 1) return "Y";
      return is_escalation;
    },
    async loadCaseGrid() {
      const rsp = await getCaseLists();
      if (rsp.success) {
        this.caseGridData = rsp.data;
        this.caseGridData.forEach((e) => {
          if (e.case_is_escalation == 0) {
            e.case_is_escalation = "N";
          } else {
            e.case_is_escalation = "Y";
          }
          e.case_open_dttm = moment(e.case_open_dttm).format(
            "yyyy-MM-DD HH:mm:ss"
          );
          e.case_close_dttm = moment(e.case_close_dttm).format(
            "yyyy-MM-DD HH:mm:ss"
          );
        });
        this.loading = false;
      }
    },
  },
  data() {
    return {
      loading: true,
      caseGridData: [],
      filteredData: [],
      statusList: [
        { text: "ALL", value: null },
        { text: "NEW", value: 1 },
        { text: "ASSESS", value: 2 },
        { text: "RESEARCH", value: 3 },
        { text: "SOLUTION", value: 4 },
        { text: "CLOSED", value: 5 },
        { text: "DELETED", value: 99 },
      ],
      escalationList: [
        { text: "ALL", value: null },
        { text: "Y", value: "Y" },
        { text: "N", value: "N" },
      ],
      filter: {
        case_id: "",
        writer: "",
        customer: "",
        product: "",
        subject: "",
        status: null,
        escalation: null,
        description: "",
      },
    };
  },
};
</script>
